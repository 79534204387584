<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Galileo Computing VueJS Dashboard
  Author: Ian Kibet
  Author URL: http://ian.galileoc.co.ke
========================================================================================== -->

<template>
  <vs-sidebar
    v-model="isSidebarActiveLocal"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? 'ADD NEW' : 'UPDATE' }}
        OPTION
      </h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isSidebarActiveLocal = false"
      />
    </div>
    <vs-divider class="mb-0" />

    <VuePerfectScrollbar
      :settings="settings"
      :key="$vs.rtl"
      class="scroll-area--data-list-add-new"
    >
      <div class="p-6">
        <vs-input
          v-validate="'required'"
          v-model="payment_option"
          label="Payment Option"
          class="mt-5 w-full"
          name="payment-option"
        />
        <span
          v-show="errors.has('payment-option')"
          class="text-danger text-sm"
          >{{ errors.first('payment-option') }}</span
        >

        <vs-checkbox v-model="status" class="mt-4">
          <span> {{ status ? 'Active' : 'Inactive' }}</span></vs-checkbox
        >

        <div class="mt-5">
          <small>Select Availability</small>
          <ul class="mt-2">
            <li v-for="branch in branchOptions" :key="branch._id" class="m-2">
              <vs-checkbox v-model="av_branch[branch._id]">{{
                branch.branch_name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
      </div>
    </VuePerfectScrollbar>

    <div slot="footer" class="flex flex-wrap items-center p-6">
      <vs-button :disabled="!isFormValid" class="mr-6" @click="submitData"
        >Submit</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >Cancel</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: null,
      rev: null,
      payment_option: '',
      status: true,
      av_branch: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    branchOptions() {
      return this.branchData ? this.branchData : [];
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.payment_option;
    },
  },
  pouch: {
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
        sort: [
          {
            name: 'asc',
          },
        ],
      };
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { _id, _rev, name, status, branches } = JSON.parse(
          JSON.stringify(this.data),
        );
        this.id = _id;
        this.rev = _rev;
        this.payment_option = name;
        this.status = status;
        if (typeof branches != 'undefined') {
          this.av_branch = branches;
        } else {
          this.av_branch = {};
        }

        this.initValues();
      }
    },
  },
  methods: {
    initValues() {
      if (this.data._id) return;
      this.id = null;
      this.payment_option = '';
      this.status = true;
      this.av_branch = {};
    },
    submitData() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.notify({
            color: 'warning',
            title: 'Processing',
            text: 'Processing Data',
          });
          const obj = {
            _id: this.id,
            _rev: this.rev,
            payment_option: this.payment_option,
            status: this.status,
            branches: this.av_branch,
          };

          if (Object.keys(this.av_branch).length < 1) {
            obj.branches = undefined;
          }

          if (this.id !== null && this.rev !== null) {
            this.$store
              .dispatch('payment_options/updateOption', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Payment Option Updated Successfully',
                  text: 'You have successfully updated a payment option',
                });
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                console.error(err);
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
              });
          } else {
            delete obj._id;
            delete obj._rev;
            this.$store
              .dispatch('payment_options/addOption', obj)
              .then(() => {
                this.$vs.notify({
                  color: 'success',
                  title: 'Payment Option Added Successfully',
                  text: 'You have successfully added a new payment option',
                });
                this.$emit('closeSidebar');
                this.initValues();
              })
              .catch((err) => {
                console.error(err);
                this.$vs.notify({
                  color: 'danger',
                  title: 'Error',
                  text: err,
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
